<template>
  <b-card>
    <div slot="header">
      <strong>Remover Usuários de Grupos</strong>
    </div>
    <p><router-link :to="{ name: 'Listar Usuarios de Grupos' }">Listar Usuarios de Grupo</router-link></p>
    

    <notification v-bind:notifications="notifications"></notification>

    <form v-on:submit.prevent="deleteUsuariosDeGrupo">
      <div class="alert alert-danger">
        <strong>Atenção</strong>
        <p>
          Deseja realmente remover o vinculo deste grupo?<br>
          Esta ação não é reversível.
        </p>
        <p><button class="btn btn-danger">Remover usuario de grupo</button></p>
      </div>
    </form>

  </b-card>
</template>

<script>
import Notification from './Notificar.vue'
export default {
  data () {
    return {
      notifications: []
    }
  },
  beforeCreate: function () {
    if (!this.$session.exists()) {
      this.$router.push('/login')
    }
  },
  created: function () {
    this.verificaSessao()
  },
  methods: {
    verificaSessao: function () {
      if (!this.$session.exists()) { this.$session.start() }
      this.$http.get(this.URLApi + 'user/session', {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then((response) => {
        this.getUsuariosDeGrupo()
        return true
      }, (response) => {
        var m = response.body.error.code
        if (m === 401) {
          this.$notify({
            type: 'danger',
            title: 'Sessão expirada',
            text: 'Seu tempo de logado expirou.'
          })
        }
        this.$router.push('/login')
      })
    },
    getUsuariosDeGrupo: function () {
      this.campos('filtro_User_Grupo', 'ugrp_ID = ' + this.$route.params.id).then(
        (response) => {
          var n = response.name
          var g = response.grp_mnemonico
          this.notifications.push({
            type: 'primary',
            message: 'Removendo ' + n + ' do grupo ' + g + ''
          })
        },
        (response) => {}
      )
    },
    deleteUsuariosDeGrupo: function () {
      this.remover('gp_User_Grupo', 'ugrp_ID = ' + this.$route.params.id).then(
        (response) => {
          this.$router.push({name: 'Listar Usuarios de Grupos'})
        },
        (response) => {}
      )
    }
  },
  components: {
    'notification': Notification
  }
}
</script>
